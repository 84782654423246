import React from "react";
import Layout from "../../Layout";
import { Box, Container, Heading } from "@chakra-ui/react";
import { useLocale } from "locales";

import AboutEN from "./About.en";
import AboutFR from "./About.fr";
import AboutES from "./About.es";

const langMap = {
  en: AboutEN,
  fr: AboutFR,
  es: AboutES,
}

export default function Team() {
  const { tr, lang } = useLocale();
  let Content = langMap[lang] || langMap.en;
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Content />
        </Box>
      </Container>
    </Layout>
  );
}
