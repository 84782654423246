import { useLocale } from "locales";
import React from "react";
import { Routes, Route } from "react-router-dom";

// import ComingSoon from "components/ComingSoon";
import Error404 from "./pages/Error404";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import ComingSoon from "./pages/ComingSoon";
import * as foundation from "./pages/Foundation";

console.log(foundation.About)

export default function CommonRoutes() {
  const locale = useLocale();
  const basePath = locale.baseURL.pathname;
  return (
    <Routes>
      <Route path={basePath}>
        <Route path="" element={<Home />} />
        <Route path="foundation">
          <Route path="team" element={<foundation.Team />} />
          <Route path="about" element={<foundation.About />} />
        </Route>
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<Error404 />} />
      </Route>
      {/* Unknown locale... */}
      <Route path="*" element={<Error404 unknownLocale />} />
    </Routes>
  );
}
