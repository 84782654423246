import React from "react";
import { Image, useColorMode } from "@chakra-ui/react";
import logoSVGDark from "../assets/logo.svg";
import logoSVGLight from "../assets/logo-light.svg";

export default function Logo({ props, variant }) {
  // todo: overridable
  const { colorMode } = useColorMode();
  let v = variant;
  if (!v) {
    // use colorMode to guess
    if (colorMode === "dark") {
      variant = "light";
    } else {
      variant = "dark";
    }
  }

  const logoSVG = variant === "light" ? logoSVGLight : logoSVGDark;

  return <Image src={logoSVG} alt="Notas de Alegria logo" {...props} />;
}
