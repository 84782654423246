import en from "./en.json";
import fr from "./fr.json";
import es from "./es.json";

const resources = {
  en,
  fr,
  es,
};

export default resources;
