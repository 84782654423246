import React from "react"
import { Stack, Text, Heading } from "@chakra-ui/react";


export default () => (
  <Stack spacing={6}>
    <Heading size="3xl" color="palette.blue.1">
      Notas de Alegría
    </Heading>
    <Heading size="2xl">La musique comme projet de société à Las Terrenas</Heading>
    <Text>
      « Les enfants qui sont pauvres sur le plan matériel acquièrent une richesse
      spirituelle grâce à la musique. Et une fois que la musique leur a apporté
      de telles richesses, leurs cerveaux, âmes et esprits peuvent les porter
      vers l’avant et toujours plus haut. »
    </Text>
    <Text>
      Ces paroles d’espoir du chef d’orchestre vénézuélien José Antonio Abreu,
      Caroline Lachance les fait siennes pour la nouvelle fondation Notas de
      Alegría. « Je veux faire en sorte que la musique et la culture se
      développent à Las Terrenas ». Mais avec une forte consonance sociale,
      ajoute la directrice du projet. « Le but est de donner l’occasion aux
      enfants venant de familles défavorisées ou exclues socialement la chance
      d’apprendre la musique classique pour développer des valeurs telles que
      l’apprentissage collectif et le travail d’équipe ».
    </Text>
    <Text>
      Notas de Alegría ne s’adresse donc pas uniquement aux enfants. Les
      parents doivent aussi être interpellés. « Il faut que ça devienne un
      mouvement qui crée quelque chose de positif dans la communauté ».
    </Text>
    <Heading size="2xl">Une pédagogie humaniste éprouvée </Heading>
    <Text>
      Durant son parcours en tant qu’enseignante de musique à Montréal,
      Caroline a entendu parler du programme El Sistema, créé en 1975 par le
      Maestro José Antonio Abreu, également à l’origine de l’Orquesta Juvenil e
      infantil. Le programme offre l’occasion aux enfants défavorisés et exclus
      socialement d’avoir accès à des cours de musique gratuits. « J’ai été
      impressionnée par le niveau qu’atteignaient les musiciens à travers leur
      formation musicale. J’ai aussi été très touchée par le fait que la
      musique soit si valorisée alors que dans les pays occidentaux, les
      programmes de musique disparaissent des écoles » dit Caroline Lachance.
    </Text>
    <Text>
      Plus de 700 000 jeunes musiciens au Venezuela ont bénéficié du programme
      et le pays compte désormais plus de 250 orchestres philharmoniques.
      Plusieurs des musiciens issus d’El Sistema ont atteint la célébrité. Par
      exemple, un ancien élève de José Antonio Abreu, Rafael Payare, est devenu
      en 2021 le directeur musical de l’Orchestre Symphonique de Montréal. 60
      autres pays dans le monde se sont inspirés de ce programme.
    </Text>
    <Text>
      En République Dominicaine, c’est la Fondation Fiesta Clasica qui
      représente le programme El Sistema depuis 2015. Basée à Santo Domingo,
      Fiesta Clasica compte 200 élèves et plus de 15 professeurs. Fiesta
      Clasica a une vocation sociale avant d’être musicale. En l’occurrence,
      elle permet à des enfants de vivre pendant leurs temps libres une
      expérience de groupe au travers de l’apprentissage de la musique. 
    </Text>
    <Text>
      Fiesta Clasica appuie maintenant la démarche de Notas de Alegría, qui
      compte actuellement 25 enfants. Les défis sont nombreux pour l’équipe de
      5 membres de Notas de Alegría : recherches de locaux, d’enseignants, de
      fonds, etc. « Pour la réussite de ce projet, nous aurons besoin de toute
      la communauté : des parents d’élèves, des élus locaux, des amis et des
      donateurs ».

    </Text>
    <Text>
      Que la musique devienne ainsi un vecteur de changement social fait partie
      intégrante de la stratégie de Notas de Alegría. La fondation veut non
      seulement développer la culture, mais également un sentiment
      d’appartenance à la communauté, afin que l’on prenne notamment conscience
      que les enfants peuvent devenir musiciens.
    </Text>
    <Heading size="2xl">L’orchestre comme lieu de partage</Heading>
    <Text>
      Leur apprentissage tel que défini par El Sistema repose principalement
      sur une expérience d’ensemble. Les élèves apprennent et pratiquent en
      groupe, mais aussi par sections, sans toutefois négliger les leçons
      individuelles quand le besoin se fait sentir.
    </Text>
    <Text>
      L’orchestre devient ainsi un lieu d’investissement partagé salutaire. «
      En passant plusieurs heures par semaine à l'orchestre ou à la chorale »,
      dit Caroline Lachance, « les jeunes sont retirés des dures réalités de la
      rue (consommation de drogues, prostitution, violence). Le programme leur
      permet de consacrer leur temps à une activité qui donne un sens à leur
      vie ».
    </Text>
    <Text>
      Les performances devant un public sont encouragées, afin que les enfants
      aient une chance de s’exprimer, de monter sur une scène, afin de redonner
      à la communauté. L’idée, souhaite la fondation, c’est que les enfants
      acquièrent des valeurs, que les jeunes musiciens développent l’estime de
      soi, le sentiment de sécurité, la socialisation, la discipline et des
      habitudes d’études. 
    </Text>
    <Text>
      Notas de Alegría mise beaucoup sur l’aspect formation. La fondation veut
      que les jeunes apprennent à être persistants, à travailler de façon
      constante pour atteindre des objectifs et à partager avec leurs pairs,
      dans un climat de tolérance et de solidarité. Afin qu’au bout du compte,
      cela se reflète sur la communauté et fasse en sorte de créer une
      meilleure cohésion sociale. 
    </Text>
    <Heading size="2xl">Le financement</Heading>
    <Text>
      Comment Notas de Alegría compte s'y prendre pour se financer ? « La
      fondation doit elle-même faire ses preuves ! », indique Caroline
      Lachance. « L'équipe est formée de gens influents qui ont beaucoup de
      contacts en République dominicaine et ailleurs, pour aider la fondation à
      amasser des fonds ». 
    </Text>
    <Text>
      De plus, de multiples concerts vont être organisés au cours de la
      prochaine année pour financer le programme, ajoute la tête de pont de
      Notas de Alegría. Elle compte également sur une participation locale aux
      efforts de la fondation.
    </Text>
    <Text>
      « Rendre le monde meilleur est la responsabilité de chacun de nous » José
      Antonio Abreu était un visionnaire, emboîtons-lui le pas !
    </Text>
    <Text>
      Bienvenue à tous dans Notas de Alegria !
    </Text>
  </Stack>
) 
