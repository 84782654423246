import React from "react";
import Logo from "../Logo";
import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useLocale } from "locales";

export default function NavBarLogo() {
  const { region, lang } = useLocale();
  let regionBasePath = new URL(`https://${region.baseUrl}`).pathname;
  if (regionBasePath === "/") regionBasePath = "";
  const basePath = `${regionBasePath}/${lang}`;
  return (
    <Box w="70px">
      <Link to={basePath}>
        <Logo variant="light" />
      </Link>
    </Box>
  );
}
