import React from "react"
import { Stack, Text, Heading } from "@chakra-ui/react";


export default () => (
  <Stack spacing={6}>
    <Heading size="3xl" color="palette.blue.1">
      Notas de Alegría
    </Heading>
    <Heading size="2xl">Music as a social project in Las Terrenas</Heading>
    <Text>
      “Children who are materially poor gain spiritual wealth through
      music. And once music has brought them such riches, their brains, souls
      and spirits can carry them forward and upward".
    </Text>
    <Text>
      Notas de Alegría project director Caroline Lachance makes her own these
      words of hope from the late Venezuelan conductor José Antonio Abreu. “I
      want to make sure that music and culture develop in Las Terrenas”, she
      says, but with a strong social overtone. "The aim is to give children
      from disadvantaged or socially excluded families the chance to learn
      classical music, in order to develop values such as
      collective learning and teamwork".
    </Text>
    <Text>
      Notas de Alegría is therefore not just for children. Parents should also
      be involved. "It has to become a movement that creates something positive
      in the community."
    </Text>
    <Heading size="2xl">A proven humanist pedagogy</Heading>
    <Text>
      During her career as a music teacher in Montreal, Caroline heard about
      the El Sistema program, created in 1975 by Maestro José Antonio Abreu who
      was also at the origin of the Orquesta Juvenil e infantil. The program
      offers disadvantaged and socially excluded children the opportunity to
      have access to free music lessons. “I was impressed by the level reached
      by the musicians through their musical training. I was also very moved by
      the fact that music is so valued when in Western countries, music
      programs are disappearing from schools”, says Caroline Lachance.
    </Text>
    <Text>
      Some 700 000 young musicians in Venezuela alone have benefited from the program
      and the country now has more than 250 philharmonic orchestras. Several of
      the musicians from El Sistema have achieved stardom. For example, a
      former student of José Antonio Abreu, Rafael Payare, became in 2021 the
      musical director of the Montreal Symphony Orchestra. Sixty other
      countries around the world have been inspired by this program.
    </Text>
    <Text>
      In the Dominican Republic, the Fiesta Clasica Foundation has represented
      the El Sistema program since 2015. Based in Santo Domingo, Fiesta Clasica
      has 200 students and more than 15 teachers. It has a social vocation
      before being musical. In this case, it allows children to practice in
      their free time a group experience through learning music.
    </Text>
    <Text>
      Fiesta Clasica now supports Notas de Alegria, which currently has 25 children.
      The challenges are numerous for Notas de Alegría’s team of 5 members:
      finding premises, teachers, funds, etc. “For the success of this project
      we will need the whole community; parents of students, local elected
      officials, friends and donors”.
    </Text>
    <Text>
      That music becomes a vehicle for social change is an integral part of Notas de
      Alegría's strategy. The foundation not only wants to develop culture, but
      also a sense of belonging to the community, so that it notably becomes
      aware that children can become musicians.
    </Text>
    <Heading size="2xl">The orchestra as a place of sharing</Heading>
    <Text>
      These children’s way of learning, as defined by El Sistema, is mainly
      based on an overall experience. Students learn and practice in groups,
      but also in sections. Individual lessons are provided when the need
      arises.
    </Text>

    <Text>
      The orchestra thus becomes a beneficial place of shared interest. “By
      spending several hours a week in an orchestra or a choir,” says Caroline
      Lachance, “young people are removed from the harsh realities of the
      street (drug use, prostitution, violence). The program allows them to
      devote their time to an activity that gives meaning to their lives”.
    </Text>

    <Text>
      Performances in front of an audience are encouraged, so children have a
      chance to express themselves, to take the stage and give back to the
      community. The idea here, in the eyes of the foundation, is that children
      acquire values and that young musicians develop self-esteem, a sense of
      security, socialization, discipline and study habits.
    </Text>

    <Text>
      Notas de Alegría focuses a lot on the training aspect. It wants young
      people to learn to be persistent, to work steadily to achieve goals and
      to share with their peers, in an atmosphere of tolerance and solidarity.
      So that in the end, this learning is reflected in the community as to
      create better social cohesion.
    </Text>

    <Heading size="2xl">Funding</Heading>
    <Text>
      How will Notas de Alegria finance itself? “The foundation needs to prove
      its worth! ", says Caroline Lachance. "The team is made up of influential
      people who have a lot of contacts in the Dominican Republic and
      elsewhere, to help the foundation raise funds."
    </Text> 
    <Text>
      Moreover, multiple concerts will be organized over the next year to finance the
      program, says the head of Notas de Alegría. She also relies on local
      participation to help the foundation.
    </Text>

    <Text>
      "Making the world a better place is everyone's responsibility" José Antonio
      Abreu was a visionary, let's follow in his footsteps!
    </Text>

    <Text>Welcome everyone to Notas de Alegria!</Text>
  </Stack>
) 
