import React from "react"
import { Stack, Text, Heading } from "@chakra-ui/react";


export default () => (
  <Stack spacing={6}>
    <Heading size="3xl" color="palette.blue.1">
      Notas de Alegría
    </Heading>
    <Heading size="2xl"></Heading>
    <Text>
      “Un niño materialmente pobre se convierte en rico espiritual a través de la música. Y una vez que ya es un rico espiritual a través de la música, su mente, su alma, su espíritu están preparados para salir adelante.”
    </Text>
    <Text>
      Estas palabras de esperanza del director de orquesta venezolano José Antonio Abreu, Caroline Lachance las hacen suyas para la nueva fundación Notas de Alegría. “Quiero asegurarme de que la música y la cultura se desarrollen en Las Terrenas”. Pero con una consonancia social fuerte, añade la directora del proyecto. “El objetivo es dar a los niños de familias desfavorecidas o en situación de exclusión social la posibilidad de aprender música clásica para desarrollar valores como el aprendizaje colectivo y el trabajo en equipo”.
    </Text>
    <Text>
      Notas de Alegría, por tanto, no es solamente para niños. Los padres también deben participar. "Tiene que convertirse en un movimiento que cree algo positivo en la comunidad".
    </Text>
    <Heading size="2xl">Una pedagogía humanista probada</Heading>
    <Text>
      Durante su carrera como profesora de música en Montreal, Caroline ha oído hablar del programa El Sistema, creado en 1975 por el maestro José Antonio Abreu, también en el origen de la Orquesta Juvenil e infantil. El programa ofrece a niños desfavorecidos y excluidos socialmente la oportunidad de acceder a clases de música gratis. “Me impresionó el nivel que alcanzaron los músicos a través de su formación musical. También me conmovió mucho el hecho de que la música sea tan valorada cuando en los países occidentales los programas musicales están desapareciendo de las escuelas”, dice Caroline Lachance.
    </Text>
    <Text>
      Más de 700.000 jóvenes músicos en Venezuela se han beneficiado del programa y el país ahora cuenta con más de 250 orquestas filarmónicas. Varios de los músicos que salieron de El Sistema han alcanzado la celebridad. Por ejemplo, un ex alumno de José Antonio Abreu, Rafael Payare, se convirtió en 2021 en el director musical de la Orquesta Sinfónica de Montreal. Otros 60 países de todo el mundo se han inspirado en este programa.
    </Text>
    <Text>
      En República Dominicana, la Fundación Fiesta Clásica representa el programa El Sistema desde 2015. Con sede en Santo Domingo, Fiesta Clásica cuenta con 200 alumnos y más de 15 profesores. Fiesta Clásica tiene una vocación social antes que musical. En este caso, permite que los niños practiquen en su tiempo libre una experiencia grupal a través del aprendizaje musical.
    </Text>
    <Text>
      Fiesta Clásica ahora apoya a Notas de Alegría, la cual cuenta actualmente 25 niños. Los desafíos son numerosos para el equipo de 5 miembros de Notas de Alegría: búsqueda de locales, de profesores, de fondos, etc. “Para el éxito de este proyecto, necesitaremos de toda la comunidad; los padres de estudiantes, los representantes locales, los amigos y los donantes”.
    </Text>
    <Text>
      Que la música se convierta así en un vehículo de cambio social es parte integral de la estrategia de Notas de Alegría. La fundación no solo quiere desarrollar la cultura, sino también el sentido de pertenencia a la comunidad, para que nos demos cuenta en particular de que los niños pueden convertirse en músicos.
    </Text>
    <Heading size="2xl">La orquesta como lugar de compartir</Heading>
    <Text>
      Su aprendizaje, tal como lo define El Sistema, se basa principalmente en una experiencia global. Los alumnos aprenden y practican en grupo, pero también por secciones, sin descuidar las lecciones individuales cuando surge la necesidad.
    </Text>
    <Text>
      La orquesta se convierte así en un lugar de inversión benéfica compartida. “Al pasar varias horas a la semana en la orquesta o el coro”, dice Caroline Lachance, “los jóvenes se alejan de las crudas realidades de la calle (uso de drogas, prostitución, violencia). El programa les permite dedicar su tiempo a una actividad que da sentido a sus vidas”.
    </Text>
    <Text>
      Se fomentan las actuaciones frente a una audiencia, para que los niños tengan la oportunidad de expresarse, subir al escenario y retribuir a la comunidad. La idea, desea la fundación, es que los niños adquieran valores, que los jóvenes músicos desarrollen autoestima, sentido de seguridad, socialización, disciplina y hábitos de estudio.
    </Text>
    <Text>
      Notas de Alegría apuesta mucho por el aspecto formativo. La fundación quiere que los jóvenes aprendan a ser perseverantes, a trabajar con constancia para alcanzar objetivos y a compartir con sus pares, en un ambiente de tolerancia y solidaridad. Para que al final, esto se refleje en la comunidad y se cree una mejor cohesión social.
    </Text>
    <Heading size="2xl">El financiamiento</Heading>
    <Text>
      ¿Cómo pretende financiarse Notas de Alegría? “¡La fundación debe probarse a sí misma!” dice Caroline Lachance. "El equipo está formado por personas influyentes que tienen muchos contactos en República Dominicana y en otros lugares para ayudar a la fundación a recaudar fondos".

    </Text>
    <Text>
      Además, se organizarán múltiples conciertos a lo largo del próximo año para financiar la programación, añade la cabeza de puente de Notas de Alegría. También cuenta con la participación local en los esfuerzos de la fundación.

    </Text>
    <Text>
      ¡"Hacer del mundo un mejor lugar es responsabilidad de todos"! José Antonio Abreu fue un visionario, ¡sigamos sus pasos!
    </Text>
    <Text>
      ¡Bienvenidos a todos a Notas de Alegría!
    </Text>
  </Stack>
) 
