// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react";

import { mode } from "@chakra-ui/theme-tools";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    green: "#05a649",
  },
  palette: {
    lightBlue: {
      1: "#46c9db",
      2: "#3badbd"
    },
    green: {
      1: "#05a649",
      2: "#06873a",
    },
    link: "#4081e3",
    blue: {
      1: "#122646",
      2: "#132542",
      3: "#003f7d",
    },
  },
};

const sizes = {
  sizes: {
    maxLineWidth: "1024px",
  },
};

const global = (props) => ({
  body: {
    bg: mode("white", "palette.blue.1")(props),
  },
});

const config = {
  initialColorMode: "light",
};

const theme = extendTheme({ styles: { global }, colors, sizes, config });

export default theme;
