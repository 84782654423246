import {
  Container,
  Box,
  Text,
  Stack,
  Link,
  Select,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useLocale } from "../../locales";
import { Link as RLink } from "react-router-dom";
import { ExternalLinkIcon, Icon } from "@chakra-ui/icons";
import { BsTwitter, BsInstagram } from "react-icons/bs";

function FooterNotice() {
  const { tr } = useLocale();
  return (
    <Box textAlign="center">
      <Text fontSize="xs" color="gray.500" margin="0">
        {tr("footerNotice")}
      </Text>
    </Box>
  );
}

function FooterNav() {
  const { tr, url } = useLocale();
  const NAV_ITEMS = [
    {
      label: tr("donate"),
      to: url("contact"),
    },
    {
      label: tr("nav.contact"),
      to: url("contact"),
    },
  ].filter((e) => e !== null);

  return (
    <Box textAlign="center" margin="0 auto">
      <Stack
        direction={"row"}
        spacing={{ base: 8 }}
        alignItems="center"
        justify="center"
      >
        {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Link
              as={navItem.to && RLink}
              to={navItem.to}
              isExternal={navItem.isExternal}
              href={navItem.href ?? "#"}
              fontSize={"sm"}
              fontWeight={500}
              color={"whiteAlpha.800"}
              _hover={{
                textDecoration: "none",
                color: "white",
              }}
              alignItems="center"
              display="flex"
            >
              {navItem.icon && (
                <Icon
                  color="whiteAlpha.800"
                  as={navItem.icon}
                  marginRight="4px"
                />
              )}{" "}
              {navItem.label}{" "}
              {navItem.isExternalIcon && <ExternalLinkIcon mx="2px" />}
            </Link>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

function FooterNavSocials() {
  const { tr, trExists } = useLocale();
  const socials = [
    {
      type: "instagram",
      icon: BsInstagram,
      toUrl(username) {
        return `https://instagram.com/${username}/`;
      },
    },
    {
      type: "twitter",
      icon: BsTwitter,
      toUrl(username) {
        return `https://twitter.com/${username}/`;
      },
    },
  ]
    .map((s) => ({
      ...s,
      trKey: `socials.${s.type}`,
    }))
    .filter((s) => trExists(s.trKey))
    .map((s) => ({ ...s, username: tr(s.trKey) }));
  console.log(socials);
  if (!socials.length) return null;

  return (
    <Box textAlign="center" margin="0 auto">
      <Stack direction={"row"} spacing={4} alignItems="center" justify="center">
        {socials.map((s) => (
          <Box key={s.type}>
            <Link
              isExternal={true}
              href={s.toUrl(s.username)}
              fontSize={"sm"}
              fontWeight={500}
              color={"whiteAlpha.800"}
              _hover={{
                textDecoration: "none",
                color: "white",
              }}
              alignItems="center"
              display="flex"
            >
              {s.icon && (
                <Icon color="whiteAlpha.800" as={s.icon} marginRight="4px" />
              )}{" "}
              {s.username}{" "}
            </Link>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

function FooterLanguagePicker() {
  const locale = useLocale();
  const { langs } = locale.region;
  const { tr, switchLang } = locale;

  if (langs.length === 2) {
    const otherLang = langs.filter((lang) => lang !== locale.lang)[0];
    return (
      <Link
        onClick={() => switchLang(otherLang)}
        maxW="sm"
        color="white"
        display="inline-block"
        backgroundColor="palette.blue.1"
        padding="8px"
        borderRadius="md"
      >
        {tr(`langs.${otherLang}`)}
      </Link>
    );
  } else if (langs.length > 2) {
    const onChange = (event) => {
      const newLang = event.target.value;
      switchLang(newLang);
    };

    return (
      <Box maxW="sm" color="white">
        <Select size="sm" value={locale.lang} onChange={onChange}>
          {langs.map((lang) => (
            <option key={lang} value={lang}>
              {tr(`langs.${lang}`)}
            </option>
          ))}
        </Select>
      </Box>
    );
  } else {
    return;
  }
}

export default function Footer() {
  return (
    <Container maxW="container.xl">
      <VStack marginY={{ base: 12 }} spacing={{ base: 10 }}>
        <FooterNav />
        {/* <FooterNavSocials /> */}
        <FooterLanguagePicker />
        <FooterNotice />
      </VStack>
    </Container>
  );
}
