import React from "react";
import Layout from "../../Layout";
import { Box, Container, Heading, Stack, Text, Image, VStack, HStack, StackDivider } from "@chakra-ui/react";
import { useLocale } from "locales";

import chantal from "./photos/chantal.jpeg"
import rachel from "./photos/rachel.jpeg"
import caroline from "./photos/caroline.jpeg"

const photos = { chantal, rachel, caroline };

function TeamMember({ username, name, title, bio1, bio2 }) {
  return <Stack spacing="3">
    <Heading>{name}</Heading>
    <Heading size="md">{title}</Heading>
    <Stack spacing="4" direction={{ base: "column", md: "row" }}>
      <Image src={photos[username]} alt={name} boxSize="200px" marginTop="1" />
      <Stack>
        <Text>{bio1}</Text>
        <Text>{bio2}</Text>
      </Stack>
    </Stack>
  </Stack>
}


export default function Team() {
  const { tr } = useLocale();
  const teamMembers = ["caroline", "chantal", "rachel"].map(username => {
    const t = (k) => (tr(`pages.team.${username}.${k}`))
    return {
      username,
      name: t("name"),
      title: t("title"),
      bio1: t("bio1"),
      bio2: t("bio2")
    }
  });
  return (
    <Layout>
      <Container maxW="container.xl">
        <Box p={{ base: 8 }}>
          <Heading size="3xl" color="palette.blue.1" mb={8}>
            {tr("pages.team.header")}
          </Heading>
          <VStack spacing={6} divider={<StackDivider borderColor='gray.200' />}>
            {teamMembers.map(teamMember => <TeamMember {...teamMember} />)}
          </VStack>
        </Box>
      </Container>
    </Layout>
  );
}
